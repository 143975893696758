<template>
  <div>
    <v-container>

      <v-row justify="center">
        <v-col cols="12" lg="10">

          <v-alert v-if="offline" border="bottom" class="mb-3 fs-14"
                   color="warning" dark
                   dense icon="mdi-access-point-off"
                   prominent>
            Vous avez perdu votre connexion Internet. L'application passera automatiquement en mode hors ligne.
          </v-alert>

          <div v-if="isLoading">
            <v-card class="rounded-lg shadow mb-3">
              <v-card-text>
                <v-row>
                  <v-col cols="8">
                    <v-skeleton-loader type="article,actions"/>
                  </v-col>
                  <v-col cols="4">
                    <v-skeleton-loader type="image"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-col v-for="item in 5" :key="item" cols="12">
                  <v-skeleton-loader type="list-item-avatar-three-line"/>
                </v-col>
              </v-card-text>
            </v-card>
          </div>

          <div v-else>

            <CampaignInfoCard :campaign="campaign"/>

            <v-alert border="bottom" class="fs-14" color="primary" dark dismissible prominent>
              <p>
                La couleur verte indique la date d'aujourd'hui.
              </p>
              <p>
                Veuillez visiter le magasin dans l'ordre dans lequel il est classé dans la liste ci-dessous.
              </p>
              Si le magasin est fermé,Tu peux aller vers le
              <span class="font-weight-medium">
                  Backup <v-icon color="primary" small>mdi-backup-restore</v-icon>
               </span>
            </v-alert>

            <div class="text-end mb-3">
              <v-btn color="primary"
                     dark
                     text
                     @click="showCampaign">
                <v-icon left>mdi-refresh</v-icon>
                Actualiser la liste
              </v-btn>
            </div>

            <v-card class="shadow rounded-lg">

              <div v-if="dates.length">

                <v-card-title class="pa-0">
                  <v-tabs class="rounded-t-lg"
                          height="60">
                    <v-tab value="list" @click="view = 'list'">
                      <v-icon left>mdi-calendar-month-outline</v-icon>
                      Programme
                    </v-tab>
                    <v-tab value="sync" @click="view = 'sync'">
                      <v-icon left>mdi-sync</v-icon>
                      <v-badge :content="sales.length + pointings.length"
                               color="primary">
                        Sync
                      </v-badge>
                    </v-tab>
                  </v-tabs>

                </v-card-title>

                <v-divider/>

                <v-card-text>
                  <v-expansion-panels v-if="view === 'list'" flat>
                    <v-expansion-panel v-for="(date,index) in dates"
                                       :key="index"
                                       active-class="primary white--text"
                                       class="table-border mb-3 rounded-lg"
                    >
                      <v-expansion-panel-header expand-icon="">

                        <template v-slot:default="{ open }">
                          <v-row align="center" no-gutters>

                            <v-col cols="4">
                              <div class="d-flex align-center">
                                <div v-if="date.date === dateNow">
                                  <v-avatar :color="open ? 'white' : 'secondary'" size="35">
                                    <v-icon :color="open ? 'secondary' : 'white'">mdi-calendar</v-icon>
                                  </v-avatar>
                                </div>

                                <div v-else>
                                  <v-avatar :color="open ? 'white' : 'primary'" size="35">
                                    <v-icon :color="open ? 'primary' : 'white'">mdi-calendar</v-icon>
                                  </v-avatar>
                                </div>
                                <div class="ml-2">
                                  {{ $func.dateFormat(date.date) }}
                                </div>
                              </div>
                            </v-col>

                            <v-col class="text-end" cols="8">
                              <v-icon :color="date.date === dateNow && !open ? 'secondary' : open ? 'white' : 'primary'">
                                {{ open ? "mdi-minus-circle" : "mdi-plus-circle" }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </template>

                      </v-expansion-panel-header>
                      <v-expansion-panel-content>

                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                            <tr v-for="(salepoint,i) in date.animator_salepoints"
                                :key="i"
                            >

                              <td width="5%">
                                <div class="py-4">
                                  <v-avatar v-if="salepoint.salepoint.image" size="40">
                                    <v-img :src="$baseUrl + salepoint.salepoint.image"
                                           class="zoom-pointer"
                                           @click="$refs.lightbox.open(salepoint.salepoint.image)"></v-img>
                                  </v-avatar>

                                  <v-avatar v-else
                                            :class="$func.randomColor()"
                                            class="font-weight-medium"
                                            size="40">
                                    {{ $func.avatarName(salepoint.salepoint.name) }}
                                  </v-avatar>
                                </div>
                              </td>

                              <td>
                                {{ salepoint.salepoint.name }}
                                <span v-if="salepoint.is_backup" class="d-block primary--text font-weight-medium">
                                    Backup <v-icon color="primary" small>mdi-backup-restore</v-icon>
                                  </span>
                              </td>

                              <td>
                                <div class="d-flex align-center">

                                  <v-bottom-sheet v-if="$vuetify.breakpoint.mobile">
                                    <template v-slot:activator="{ on }">
                                      <v-btn color="primary"
                                             icon
                                             v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>

                                    <ProgramMenu :campaign="campaign"
                                                 :date="date.date"
                                                 :salepoint="salepoint"/>

                                  </v-bottom-sheet>

                                  <v-menu v-else
                                          v-model="salepoint.salepoint.menu"
                                          :close-on-click="false"
                                          :close-on-content-click="false"
                                          bottom
                                          left
                                          origin="center center"
                                          transition="scale-transition"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-btn color="primary"
                                             icon
                                             v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card flat tile>
                                      <v-card-title>
                                      <span class="fs-15">
                                         {{ salepoint.salepoint.name }}
                                      </span>
                                        <v-spacer/>
                                        <v-icon @click="salepoint.salepoint.menu = false">mdi-close</v-icon>
                                      </v-card-title>
                                      <v-divider/>
                                    </v-card>

                                    <ProgramMenu :campaign="campaign"
                                                 :date="date.date"
                                                 :salepoint="salepoint"/>

                                  </v-menu>
                                </div>
                              </td>

                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <div v-else>
                    <Synchronize/>
                  </div>
                </v-card-text>

              </div>

              <v-card-text v-else class="text-center">
                <img :src="require('@/assets/calendar.svg')" alt="" width="350">

                <h2 class="mb-4 mt-2">
                  Oups, quelque chose s'est mal passé !
                </h2>

                <p>
                  Vos points de vente ne sont pas encore déterminés dans cette campaigne.
                </p>
              </v-card-text>

            </v-card>
          </div>

        </v-col>
      </v-row>

      <NewSaleDialog ref="newSaleDialog" @remove-query="removeQuery"/>
      <Lightbox ref="lightbox"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import CampaignInfoCard from "@/views/animator-view/campaigns/program/components/CampaignInfoCard.vue";
import ProgramMenu from "@/views/animator-view/campaigns/program/components/ProgramMenu.vue";
import moment from "moment";
import Synchronize from "@/views/animator-view/campaigns/program/components/Synchronize.vue";
import NewSaleDialog from "@/views/animator-view/campaigns/program/components/NewSaleDialog.vue";

export default {
    components: {NewSaleDialog, Synchronize, ProgramMenu, CampaignInfoCard},
    data() {
        return {
            view: 'list',
            offline: false,
            isLoading: false,
            dateNow: moment(moment.now()).format('YYYY-MM-DD')
        }
    },
    methods: {
        showCampaign() {
            this.isLoading = true
            HTTP.get('/animator-view/campaigns/' + this.$route.params.id + '/assignment-salepoints').then((res) => {
                this.isLoading = false
                if (res.data.data) {

                    let campaignId = parseInt(this.$route.params.id)
                    let checkExists = this.$store.state.programs.findIndex(el => el.campaign.id === campaignId)

                    if (checkExists === -1) {
                        this.$store.dispatch('programs', {
                            campaign: res.data.data.campaign,
                            dates: res.data.data.campaign_animator_dates,
                        })
                    } else {
                        this.$store.dispatch('updatePrograms', {
                            campaign: res.data.data.campaign,
                            dates: res.data.data.campaign_animator_dates,
                        })
                    }
                }
                this.offline = false
            }).catch(err => {
                if (!err.response) {
                    this.offline = true
                }
                this.isLoading = false
                console.log(err)
            })
        },

        /**
         *
         * @param salepoint
         * @returns {{start_time}|actions.pointing|*|null}
         */
        checkStartPointing: function (salepoint) {
            if (!salepoint.pointing) {
                let index = this.$store.state.pointings.findIndex(item =>
                    parseInt(item.campaign_id) === parseInt(this.campaign.id)
                    && parseInt(item.salepoint_id) === parseInt(salepoint.salepoint.id)
                    && item.date === this.dateNow
                    && item.type === 'start_work'
                )
                if (this.$store.state.pointings[index]) {
                    let pointing = this.$store.state.pointings[index]

                    pointing['start_time'] = pointing.time
                    return pointing

                } else {
                    return null
                }
            } else {
                if (salepoint.pointing.start_time) {
                    return salepoint.pointing
                } else {
                    return null
                }
            }
        },
        removeQuery() {
            this.$router.replace({'query': null}).catch(e => {
                return e
            })
        }
    },
    computed: {
        campaign() {
            let program = this.$store.state.programs.find(el => el.campaign.id === parseInt(this.$route.params.id))
            return program ? program.campaign : {}
        },
        dates() {
            let program = this.$store.state.programs.find(el => el.campaign.id === parseInt(this.$route.params.id))
            return program ? program.dates : []
        },
        sales() {
            return this.$store.state.sales.filter(el => el.campaign.id === parseInt(this.$route.params.id) && el.is_sync === false)
        },
        pointings() {
            return this.$store.state.pointings.filter(el => el.campaign_id === parseInt(this.$route.params.id) && el.is_sync === false)
        },
    },
    created() {
        this.showCampaign()

        /**
         * When click Continue
         * @type {T}
         */
        let _vm = this
        if (this.$route.query.salepoint) {
            let date = this.dates.find(el => el.date === this.dateNow)

            if (date) {
                let salepoint = date.animator_salepoints.find(el => el.salepoint_id === parseInt(this.$route.query.salepoint))
                setTimeout(function () {
                    _vm.$refs.newSaleDialog.open(_vm.campaign, salepoint.salepoint, date.date, _vm.checkStartPointing(salepoint), salepoint.is_backup)
                }, 500)

            }
        }
    }
}
</script>

<style>

</style>