<template>
    <div>
        <v-dialog v-model="dialog" max-width="500" persistent>
            <v-card flat tile>
                <v-card-title>
                    Fin de travail
                    <v-spacer/>
                    <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-subtitle>
                    <span class="fs-14 primary--text"> {{ salepoint.name }} </span>
                </v-card-subtitle>
                <v-divider/>
                <v-card-text class="pa-6">

                    <p class="text-center font-weight-medium fs-15">
                        Prendre une photo
                    </p>

                    <p class="text-center">
                        <span class="font-weight-medium warning--text">Attention,</span> Vous ne pourrez pas revenir en
                        arrière.
                    </p>

                    <v-layout align-center class="text-center mb-3" justify-center>
                        <v-flex shrink>
                            <div class="profile-img">
                                <v-img :src="image ? image : require('@/assets/no-image.png')"
                                       class="rounded-circle"
                                       height="150"
                                       width="150">
                                </v-img>
                            </div>

                            <v-btn v-if="!image"
                                   :loading="isLoading"
                                   class="btn-upload"
                                   color="primary"
                                   dark
                                   depressed
                                   fab
                                   @click="onButtonClick">
                                <v-icon>mdi-camera</v-icon>
                            </v-btn>

                            <v-btn v-if="image"
                                   class="btn-upload"
                                   color="red"
                                   dark
                                   depressed
                                   fab
                                   @click="image = null">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>

                        </v-flex>
                    </v-layout>

                    <input ref="image"
                           accept="image/*"
                           capture="user"
                           class="d-none"
                           type="file"
                           @change="uploadImage($event)">

                </v-card-text>

                <v-divider/>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn :disabled="!image"
                           color="secondary"
                           depressed
                           @click="validate">
                        <v-icon left>mdi-check</v-icon>
                        Valider
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import moment from "moment/moment";
import imageCompression from "browser-image-compression";

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            image: null,
            campaign: {},
            date: null,
            salepoint: {},
        }
    },
    methods: {
        open(campaign, date, salepoint) {
            this.campaign = campaign
            this.date = date
            this.salepoint = salepoint
            this.dialog = true
            this.reset()
        },
        reset() {
            this.image = null
        },
        async uploadImage(event) {
            this.isLoading = true
            const imageFile = event.target.files[0]
            const options = {
                maxSizeMB: 0.3,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(imageFile, options)
                if (compressedFile) {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                        this.image = reader.result
                    }
                    reader.readAsDataURL(compressedFile)
                    this.isLoading = false
                }
            } catch (error) {
                this.isLoading = false
                console.log(error)
            }
        },
        onButtonClick() {
            window.addEventListener('focus', () => {
            }, {once: true})
            this.$refs.image.click()
        },
        validate() {
            this.handleSave()
        },
        handleSave() {
            let payload = {
                created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                is_sync: false,
                campaign_id: this.campaign.id,
                salepoint_id: this.salepoint.id,
                date: this.date,
                type: 'end_work',
                time: moment().format('HH:mm:ss'),
                image: this.image,
                latitude: 0.0,
                longitude: 0.0,
            }
            this.$store.dispatch('pointing', payload)
            this.$successMessage = "Le fin de travail a été enregistré"
            this.dialog = false
            this.$emit('closeDialog')
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>
.profile-img {
    border: solid 1px #e9ebec;
    border-radius: 50%;
    padding: 5px;
}

.btn-upload {
    margin-right: -100px;
    margin-top: -65px;
}
</style>