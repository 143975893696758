<template>
  <div>

    <Lightbox ref="lightbox"/>

    <v-card class="my-3 rounded-lg shadow">
      <v-card-title>
        <span class="fs-16">{{ form.salepoint.name }}</span>
        <span v-if="is_backup" class="ml-2 fs-14 primary--text">
            Backup
            <v-icon color="primary" small>mdi-backup-restore</v-icon>
             </span>
      </v-card-title>
      <v-card-subtitle>
        Début de travail :
        <span class="font-weight-medium primary--text">
          <v-icon color="primary" dense>mdi-clock</v-icon>
                    {{ pointing && pointing.start_time ? $func.hourFormat(pointing.start_time) : '' }}
        </span>
      </v-card-subtitle>
      <v-divider/>
      <v-card-text>

        <v-expansion-panels flat>
          <v-expansion-panel class="rounded-lg table-border">
            <v-expansion-panel-header expand-icon="">

              <template v-slot:default="{ open }">
                <div class="font-weight-medium">
                  <v-icon color="secondary" left>mdi-chart-areaspline</v-icon>
                  Statistiques
                </div>
                <v-spacer/>
                <div class="text-end">
                  <v-btn icon>
                    <v-icon v-if="!open" color="secondary">mdi-plus-circle</v-icon>
                    <v-icon v-else color="secondary">mdi-minus-circle</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content>

              <v-skeleton-loader v-if="statLoading" type="article"></v-skeleton-loader>

              <v-row v-else>
                <v-col cols="12">
                  <v-alert border="bottom" class="fs-14 mb-0" color="primary" prominent text>
                    Toutes les statistiques ci-dessous synchronisées avec la base de données.
                  </v-alert>
                </v-col>
                <v-col class="text-end" cols="12">
                  <v-btn color="primary" depressed @click="$emit('getSalesStatistics')">
                    <v-icon left>mdi-refresh</v-icon>
                    Actualiser
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-card class="rounded-lg" color="primary" dark outlined>
                    <v-card-title>
                                <span class="fs-17">
                                  Ventes
                                </span>
                      <v-spacer/>
                      <v-avatar class="bg-grad-white">
                        <v-icon>mdi-shopping</v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-subtitle class="font-weight-medium">
                      Pos : {{ form.salepoint.name }}
                    </v-card-subtitle>
                    <v-card-text class="white--text">

                      <div class="rounded-lg" style="border:hsla(0,0%,100%,.12) solid 1px">

                        <v-list class="py-0 rounded-lg transparent">

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Total Général</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                {{ saleStatistics.all }}
                              </v-avatar>
                            </v-list-item-action>
                          </v-list-item>


                          <v-divider/>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon left>mdi-sign-direction</v-icon>
                                Contact direct
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                {{ saleStatistics.direct }}
                              </v-avatar>
                            </v-list-item-action>
                          </v-list-item>


                          <v-divider/>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon left>mdi-sign-direction-minus</v-icon>
                                Contact indirect
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                {{ saleStatistics.indirect }}
                              </v-avatar>
                            </v-list-item-action>
                          </v-list-item>

                          <v-divider/>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title> Type Switch</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                {{ saleStatistics.switch }}
                              </v-avatar>
                            </v-list-item-action>
                          </v-list-item>

                          <v-divider/>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title> Type Fidélisation</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                {{ saleStatistics.sale }}
                              </v-avatar>
                            </v-list-item-action>
                          </v-list-item>

                          <v-divider/>

                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>Type Refus</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                {{ saleStatistics.refusal }}
                              </v-avatar>
                            </v-list-item-action>
                          </v-list-item>

                        </v-list>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-card :max-height="$vuetify.breakpoint.mobile ? '' : '450'"
                          :min-height="$vuetify.breakpoint.mobile ? '' : '450'"
                          class="rounded-lg overflow-y-auto"
                          outlined>
                    <v-card-title>
                                <span class="fs-17">
                                  Cadeaux
                                </span>
                      <v-spacer/>
                      <v-avatar class="bg-grad-primary">
                        <v-icon color="primary">mdi-gift</v-icon>
                      </v-avatar>
                    </v-card-title>
                    <v-card-text class="px-0">

                      <div v-if="saleStatistics && saleStatistics.gifts">

                        <div v-if="saleStatistics.gifts.length">
                          <v-list class="py-0 rounded-lg" dense>

                            <template v-for="(gift,i) in saleStatistics.gifts">
                              <v-list-item :key="i" link>
                                <v-list-item-avatar class="zoom-pointer"
                                                    rounded
                                                    @click="$refs.lightbox.open(gift.image)">
                                  <v-img :src="$baseUrl+gift.image"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <v-list-item-title v-text="gift.name"></v-list-item-title>
                                  <v-list-item-subtitle class="red--text">({{ gift.actual_qty }})</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-chip :class="$func.randomColor()" class="font-weight-medium"
                                          label
                                  >
                                    {{ gift.actual_qty }} / {{ gift.initial_qty }}
                                  </v-chip>
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider v-if="i < pointing.gifts.length - 1"
                                         :key="gift.name"/>
                            </template>

                          </v-list>
                        </div>

                        <div v-else class="px-4">
                          <v-alert border="bottom" class="fs-14" color="primary" prominent text>
                            Il n'y a pas de cadeaux dans cette campagne ou ils sont tous du types digitales.
                          </v-alert>
                        </div>

                      </div>


                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
   props: ['form', 'saleStatistics', 'pointing', 'statLoading', 'is_backup']
}
</script>

<style>

</style>