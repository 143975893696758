<template>
  <div>

    <v-tabs class="mb-5" centered>
      <v-tab value="sales" @click="view = 'sales'">
        <v-icon left> mdi-shopping</v-icon>
        <v-badge :content="sales.length"
                 color="primary">
          Ventes
        </v-badge>
      </v-tab>
      <v-tab value="sync" @click="view = 'pointings'">
        <v-icon left> mdi-hand-pointing-up</v-icon>
        <v-badge :content="pointings.length"
                 color="primary">
          Pointages
        </v-badge>
      </v-tab>
    </v-tabs>

    <div v-if="view === 'pointings'">
      <v-card v-for="(item,i) in pointings" :key="i" class="rounded-lg mb-3"
              outlined>
        <v-card-text>
          <div class="d-flex">
            <div class="fs-14">
              <v-icon color="primary" left>mdi-clock-outline</v-icon>
              {{ $func.dateTimeFormat(item.created_at) }}
            </div>
            <v-spacer></v-spacer>
            <v-icon color="orange">mdi-timer-sand</v-icon>
          </div>
        </v-card-text>
      </v-card>

      <div v-if="!pointings.length" class="text-center">
        <img :src="require('@/assets/sync.svg')" alt="" width="250">
        <p class="text-grey fs-13">
          Il n'y a aucune donnée enregistrée en attente de synchronisation
        </p>
      </div>

      <div v-else class="mt-5 text-end">
        <v-btn :loading="isLoading"
               block
               color="primary"
               depressed
               large
               @click="synchronizePointings">
          <v-icon left>mdi-sync</v-icon>
          Synchroniser
        </v-btn>
      </div>

    </div>

    <div v-if="view === 'sales'">
      <v-card v-for="(item,i) in sales" :key="i" class="rounded-lg mb-3"
              outlined>
        <v-card-text>
          <div class="d-flex">
            <div class="fs-14">
              <v-icon color="primary" left>mdi-clock-outline</v-icon>
              {{ $func.dateTimeFormat(item.created_at) }}
            </div>
            <v-spacer></v-spacer>
            <v-icon color="orange">mdi-timer-sand</v-icon>
          </div>
        </v-card-text>
      </v-card>

      <div v-if="!sales.length" class="text-center">
        <img :src="require('@/assets/sync.svg')" alt="" width="250">
        <p class="text-grey fs-13">
          Il n'y a aucune donnée enregistrée en attente de synchronisation
        </p>
      </div>

      <div v-else class="mt-5 text-end">
        <v-btn :loading="isLoading"
               block
               color="primary"
               depressed
               large
               @click="synchronizeSales">
          <v-icon left>mdi-sync</v-icon>
          Synchroniser
        </v-btn>
      </div>

    </div>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            isLoading: false,
            view: 'sales'
        }
    },
    methods: {
        synchronizeSales() {
            this.isLoading = true
            HTTP.post('/animator-view/synchronize-sales', {
                sales: this.sales
            }).then(() => {
                this.isLoading = false

                let sales = this.$store.state.sales

                sales.map((item1, index) => {
                    this.sales.map(item2 => {
                        if (item1.uuid === item2.uuid) {
                            this.$store.dispatch('updateSyncSale', index)
                        }
                    })
                })

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        synchronizePointings() {
            this.isLoading = true
            HTTP.post('/animator-view/synchronize-pointings', {
                pointings: this.pointings
            }).then(() => {
                this.isLoading = false

                let pointings = this.$store.state.pointings

                pointings.map((item1, index) => {
                    this.pointings.map(item2 => {
                        if (item1.created_at === item2.created_at) {
                            this.$store.dispatch('updateSyncPointing', index)
                        }
                    })
                })

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    },
    computed: {
        sales() {
            return this.$store.state.sales.filter(el => el.campaign.id === parseInt(this.$route.params.id) && el.is_sync === false)
        },
        pointings() {
            return this.$store.state.pointings.filter(el => el.campaign_id === parseInt(this.$route.params.id) && el.is_sync === false)
        },
    },
}
</script>

<style scoped>

</style>