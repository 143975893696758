<template>
  <div>
    <v-list nav>

      <v-list-item two-line
                   @click="$refs.posDetailDialog.open(salepoint.salepoint,salepoint.is_backup)">
        <v-list-item-avatar size="25" tile>
          <v-icon color="primary">mdi-eye-plus</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Détail du pos
          <v-list-item-subtitle>
            Plus information sur le point de vente...
          </v-list-item-subtitle>
        </v-list-item-title>
      </v-list-item>

      <v-list-item :disabled="!!(date !== dateNow || checkPointing('start_work'))"
                   two-line
                   @click="$refs.pointingDialog.open('start_work',campaign,date,salepoint.salepoint)">
        <v-list-item-avatar size="25" tile>
          <v-icon color="primary">mdi-hand-pointing-up</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Début de travail
          <v-chip v-if="checkPointing('start_work')" color="success" small>
            <v-icon left small>mdi-clock-outline</v-icon>
            {{ $func.hourFormat(checkPointing('start_work').start_time) }}
          </v-chip>
          <v-list-item-subtitle>
            Prendre une photo, Entrez la quantité des cadeaux...
          </v-list-item-subtitle>
        </v-list-item-title>
        <v-list-item-action v-if="checkPointing('start_work')">
          <v-icon color="success">mdi-check-circle</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item :disabled="!!(date !== dateNow || checkPointing('end_work') || !checkPointing('start_work'))"
                   two-line
                   @click="$refs.newSaleDialog.open(campaign,salepoint.salepoint,date,checkPointing('start_work'),salepoint.is_backup)">
        <v-list-item-avatar size="25" tile>
          <v-icon color="primary">mdi-shopping</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Nouvelle vente
          <v-list-item-subtitle>
            Remplissez le formulaire client...
          </v-list-item-subtitle>
        </v-list-item-title>
        <v-list-item-action v-if="checkPointing('end_work')">
          <v-icon color="success">mdi-check-circle</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item :disabled="!!(date !== dateNow ||  checkPointing('end_work') || !checkPointing('start_work'))"
                   two-line
                   @click="$refs.pointingDialog.open('end_work',campaign,date,salepoint.salepoint)">
        <v-list-item-avatar size="25" tile>
          <v-icon color="red">mdi-hand-pointing-down</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Fin de travail
          <v-chip v-if="checkPointing('end_work')" color="success" small>
            <v-icon left small>mdi-clock-outline</v-icon>
            {{ $func.hourFormat(checkPointing('end_work').end_time) }}
          </v-chip>
          <v-list-item-subtitle>
            Prendre une photo...
          </v-list-item-subtitle>
        </v-list-item-title>
        <v-list-item-action v-if="checkPointing('end_work')">
          <v-icon color="success">mdi-check-circle</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item two-line
                   @click="$refs.salesStatisticDialog.open(campaign,date,salepoint.salepoint)">
        <v-list-item-avatar size="25" tile>
          <v-icon color="primary">mdi-chart-areaspline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          Statistiques de vente
        </v-list-item-title>
      </v-list-item>

    </v-list>

    <NewSaleDialog ref="newSaleDialog"/>
    <PointingDialog ref="pointingDialog"
                    @openSaleDialog="$refs.newSaleDialog.open($event.campaign,$event.salepoint,$event.date,checkPointing('start_work'),$event.is_backup)"/>
    <PosDetailDialog ref="posDetailDialog"/>
    <SalesStatisticDialog ref="salesStatisticDialog"/>

  </div>
</template>

<script>
import moment from "moment/moment";
import NewSaleDialog from "@/views/animator-view/campaigns/program/components/NewSaleDialog.vue";
import PointingDialog from "@/views/animator-view/campaigns/program/components/PointingDialog.vue";
import PosDetailDialog from "@/views/animator-view/campaigns/program/components/PosDetailDialog.vue";
import SalesStatisticDialog from "@/views/animator-view/campaigns/program/components/SalesStatisticDialog.vue";

export default {
    components: {PosDetailDialog, SalesStatisticDialog, PointingDialog, NewSaleDialog},
    props: ['date', 'campaign', 'salepoint'],
    data() {
        return {
            dateNow: moment(moment.now()).format('YYYY-MM-DD')
        }
    },
    methods: {
        /***
         * Check pointing by type
         * @param type
         * @returns {*|string|null|boolean}
         */
        checkPointing: function (type) {
            if (!this.salepoint.pointing) {
                let index = this.$store.state.pointings.findIndex(item =>
                    parseInt(item.campaign_id) === parseInt(this.campaign.id)
                    && parseInt(item.salepoint_id) === parseInt(this.salepoint.salepoint.id)
                    && item.date === this.date
                    && item.type === type
                )
                if (this.$store.state.pointings[index]) {
                    let pointing = this.$store.state.pointings[index]

                    if (type === 'start_work') {
                        pointing['start_time'] = pointing.time
                        return pointing
                    } else {
                        pointing['end_time'] = pointing.time
                        return pointing
                    }

                } else {
                    return null
                }
            } else {

                if (type === 'start_work' && this.salepoint.pointing.start_time) {
                    return this.salepoint.pointing
                } else if (type === 'end_work' && this.salepoint.pointing.end_time) {
                    return this.salepoint.pointing
                } else {
                    return null
                }
            }
        },
        forceUpdate(){
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>

</style>