<template>
  <div>

    <Lightbox ref="lightbox"/>

    <v-card class="rounded-lg mb-3 shadow">
      <v-card-title>
        {{ campaign.name }}
        <v-spacer/>
        <v-btn class="transform-none" color="primary" exact text to="/animator-view/campaigns">
          <v-icon left>mdi-chevron-left</v-icon>
          Retour
        </v-btn>

      </v-card-title>

      <v-card-subtitle>
        <v-chip :class="'bg-'+campaign.remaining_days.color+'-subtle'"
                :text-color="campaign.remaining_days.color"
                class="font-weight-medium"
                dark
                label
                small>
          {{ campaign.remaining_days.value }}
        </v-chip>
      </v-card-subtitle>

      <v-divider/>

      <v-card-text class="pa-4 pa-lg-8">

        <v-row>
          <v-col cols="8">
            <div class="d-block text-no-wrap">
              <v-icon color="primary" small>mdi-calendar</v-icon>
              <span class="font-weight-medium"> Date de début : </span>
              {{ $func.dateFormat(campaign.start_date) }}
            </div>

            <div class="d-block text-no-wrap mt-2">
              <v-icon color="primary" small>mdi-calendar</v-icon>
              <span class="font-weight-medium"> Date de fin : </span>
              {{ $func.dateFormat(campaign.end_date) }}
            </div>

            <div class="d-block text-no-wrap mt-2">
              <v-icon color="primary" small>mdi-tag</v-icon>
              <span class="font-weight-medium"> Type : </span>
              {{ $func.campaignType(campaign.type) }}
            </div>

            <p class="grey--text mt-2 mb-0">
              {{ campaign.description }}
            </p>
          </v-col>
          <v-col cols="4" class="text-end">
            <v-avatar rounded size="100">
              <v-img v-if="campaign.image" :src="$baseUrl + campaign.image"
                     class="zoom-pointer rounded-lg"
                     max-width="100"
                     min-height="100"
                     @click="$refs.lightbox.open(campaign.image)"/>

              <v-img v-else
                     :src="require('@/assets/no-image.png')"
                     class="rounded-lg"
                     max-width="100"
                     min-height="100"></v-img>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" lg="3">

            <v-list-item two-line class="pa-0">
              <v-list-item-avatar size="35" class="bg-primary-subtle rounded-lg" tile>
                <v-icon color="primary" small>mdi-shopping</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ campaign.total }}</v-list-item-title>
                <v-list-item-subtitle>Total</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-col>
          <v-col cols="6" lg="3">
            <v-list-item two-line class="pa-0">
              <v-list-item-avatar size="35" class="bg-orange-subtle rounded-lg" tile>
                <v-icon color="orange" small>mdi-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ campaign.switch }}</v-list-item-title>
                <v-list-item-subtitle>Type Switch</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6" lg="3">
            <v-list-item two-line class="pa-0">
              <v-list-item-avatar size="35" class="bg-blue-subtle rounded-lg" tile>
                <v-icon color="blue" small>mdi-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ campaign.sale }}</v-list-item-title>
                <v-list-item-subtitle>Type Fidélisation</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6" lg="3">
            <v-list-item two-line class="pa-0">
              <v-list-item-avatar size="35" class="bg-secondary-subtle rounded-lg" tile>
                <v-icon color="secondary" small>mdi-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ campaign.refusal }}</v-list-item-title>
                <v-list-item-subtitle>Type Refus</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

      </v-card-text>

    </v-card>
  </div>
</template>

<script>
export default {
    props: ['campaign']
}
</script>

<style scoped>

</style>