<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card flat tile>
        <v-card-title>
          {{ salepoint.name }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <v-alert v-if="offline"
                   border="bottom" class="fs-14"
                   color="warning"
                   dark dense
                   icon="mdi-access-point-off" prominent
                   t>
            Vous avez perdu votre connexion Internet. L'application passera automatiquement en mode hors ligne.
          </v-alert>

          <v-card class="rounded-lg"
                  color="primary"
                  dark outlined>
            <v-card-title>
              <span class="fs-17">
                   Ventes
                </span>
              <v-spacer/>
              <v-avatar class="bg-grad-white">
                <v-icon>mdi-shopping</v-icon>
              </v-avatar>
            </v-card-title>
            <v-card-subtitle class="font-weight-medium">
              Pos : {{ salepoint.name }}
            </v-card-subtitle>
            <v-card-text v-if="isLoading">
              <v-skeleton-loader class="white-skeleton" type="article"/>
            </v-card-text>

            <v-card-text v-else class="white--text">
                <div class="rounded-lg" style="border:hsla(0,0%,100%,.12) solid 1px">

                  <v-list class="py-0 rounded-lg transparent">

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Total Général</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                          {{ saleStatistics.all }}
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>


                    <v-divider/>

                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon left>mdi-sign-direction</v-icon>
                          Contact direct
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                          {{ saleStatistics.direct }}
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>


                    <v-divider/>

                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-icon left>mdi-sign-direction-minus</v-icon>
                          Contact indirect
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                          {{ saleStatistics.indirect }}
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title> Type Switch</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                          {{ saleStatistics.switch }}
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title> Type Fidélisation</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                          {{ saleStatistics.sale }}
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider/>

                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>Type Refus</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                          {{ saleStatistics.refusal }}
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>

                  </v-list>
                </div>
            </v-card-text>
          </v-card>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            isLoading: false,
            offline: false,
            dialog: false,
            image: null,
            campaign: {},
            date: null,
            salepoint: {},
            saleStatistics: {},
        }
    },
    methods: {
        open(campaign, date, salepoint) {
            this.campaign = campaign
            this.date = date
            this.salepoint = salepoint
            this.dialog = true
            this.getSalesStatistics()
        },
        getSalesStatistics() {
            this.offline = false
            this.isLoading = true
            HTTP.get('/animator-view/sales-statistics', {
                params: {
                    salepoint_id: this.salepoint.id,
                    campaign_id: this.campaign.id,
                    date: this.date,
                }
            }).then((res) => {
                this.isLoading = false
                this.saleStatistics = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)


                if (!err.response) {
                    this.offline = true
                }

            })
        },
    }
}
</script>

<style>
.white-skeleton .v-skeleton-loader__article {
    background: transparent !important;
}
</style>