<template>
  <div>
    <v-dialog v-model="dialog" max-width="600"  persistent>
      <v-card>
        <v-card-title>
          {{ salepoint.name }}
          <span v-if="is_backup" class="ml-2 fs-14 primary--text">
            Backup
            <v-icon color="primary" small>mdi-backup-restore</v-icon>
             </span>
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">

          <v-card class="rounded-lg" outlined>
            <v-card-text class="pa-0">

              <div class="text-center py-5">
                <v-avatar v-if="salepoint.image" size="80">
                  <v-img :src="$baseUrl + salepoint.image"
                         class="zoom-pointer"
                         @click="$refs.lightbox.open(salepoint.image)"></v-img>
                </v-avatar>

                <v-avatar v-else
                          :class="$func.randomColor()"
                          class="font-weight-medium"
                          size="80">
                    <span class="fs-16">
                        {{ salepoint.name ? $func.avatarName(salepoint.name) : '' }}
                    </span>
                </v-avatar>
              </div>

              <v-divider/>

              <v-simple-table class="table-no-radius mb-2">
                <template v-slot:default>
                  <tbody>

                  <tr class="grey lighten-4">
                    <td class="font-weight-medium">
                      Nom :
                    </td>
                    <td>{{ salepoint.name }}</td>
                  </tr>

                  <tr class="text-no-wrap">
                    <td class="font-weight-medium">
                      Code :
                    </td>
                    <td class="font-weight-medium primary--text">{{ salepoint.code }}</td>
                  </tr>

                  <tr class="grey lighten-4">
                    <td class="font-weight-medium">
                      Magasin :
                    </td>
                    <td>{{ salepoint.store_name }}</td>
                  </tr>

                  <tr>
                    <td class="font-weight-medium">
                      Type :
                    </td>
                    <td>
                      {{ salepoint.type }}
                    </td>
                  </tr>

                  <tr class="grey lighten-4">
                    <td class="font-weight-medium">
                      Numéro de téléphone :
                    </td>
                    <td>{{ salepoint.phone }}</td>
                  </tr>

                  <tr>
                    <td class="font-weight-medium">
                      Wilaya :
                    </td>
                    <td>{{ salepoint.city }}</td>
                  </tr>

                  <tr class="grey lighten-4">
                    <td class="font-weight-medium">
                      Commune :
                    </td>
                    <td>{{ salepoint.municipality }}</td>
                  </tr>

                  <tr>
                    <td class="font-weight-medium">
                      Région :
                    </td>
                    <td>{{ salepoint.region }}</td>
                  </tr>

                  <tr class="grey lighten-4">
                    <td class="font-weight-medium">
                      Address :
                    </td>
                    <td>{{ salepoint.address }}</td>
                  </tr>

                  <tr class="grey lighten-4">
                    <td class="font-weight-medium">
                      Repère :
                    </td>
                    <td>{{ salepoint.landmark }}</td>
                  </tr>

                  <tr>
                    <td class="font-weight-medium">
                      Map :
                    </td>
                    <td>
                      <v-btn class="bg-red-subtle" color="red" icon @click="openPage(salepoint)">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-btn>
                    </td>
                  </tr>

                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
            is_backup: false,
            salepoint: {},
        }
    },
    methods: {
        open(salepoint,is_backup) {
            this.dialog = true
            this.is_backup = is_backup
            this.salepoint = salepoint
        },
        openPage(salepoint) {
            window.open("https://www.google.com/maps/search/?api=1&query=" + salepoint.latitude + "," + salepoint.longitude + "", "mywindow", "menubar=1,resizable=1,width=1000,height=600");
        },
    }
}
</script>

<style>
.table-no-radius .v-data-table__wrapper {
    border-radius: 0;
}
</style>