<template>
  <div style="display: contents">
    <svg height="35" viewBox="0 0 35.635 49.579" width="60" xmlns="http://www.w3.org/2000/svg">
      <g id="g_gifty_eagle_eye-02" data-name="g gifty eagle eye-02" transform="translate(-15.187 -9.207)">
        <path id="Tracé_2747"
              d="M15.87,45.16h7.79c2.7,5.71,13.04,6.15,16.19,0h7.69c-1.12,9.87-24.06,20.28-31.64,0h-.03Z"
              data-name="Tracé 2747" fill="#ffff"
              opacity="0.3"/>
        <path id="Tracé_2748"
              d="M16.59,32.22C11.84,23.23,19.84,9.29,30.24,9.26c2.82-.12,20.57,0,20.57,0s.62,7.07-7.14,7.07c-5.59.76-14.83-2.31-19.08,3.29-2.23,3.04-3.54,6.02-1.05,10.7,7.85,12.47,23.49-1.13,15.51-10.73h0c6.96-.03,10.93,7.7,7.41,13.53-7.91,13.6-24.03,11.45-29.87-.93v.03Z"
              data-name="Tracé 2748"
              fill="#ffff"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
    name: "GiftyIcon"
}
</script>

<style scoped>

</style>