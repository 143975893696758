<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg" outlined>
          <v-card-text>
            <p>
                Le numéro de téléphone pour envoyer le cadeau
            </p>
            <form @submit.prevent="send">

              <v-text-field v-model="form.customer.phone"
                            v-number
                            disabled
                            :error-messages="errors.phone"
                            clearable
                            counter="9"
                            outlined
                            persistent-hint
                            placeholder="Numéro de téléphone *"
                            prefix="+213"
                            prepend-inner-icon="mdi-phone"
                            required
                            type="number"
              ></v-text-field>

              <v-btn :disabled="!form.customer.phone || form.customer.phone.length !== 9"
                     :loading="isLoading"
                     block
                     class="mt-3"
                     color="secondary"
                     depressed
                     type="submit">
                <v-icon left>mdi-send</v-icon>
               Envoyer et sauvgarder
              </v-btn>

            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

   <ConfirmDialog ref="confirmDialog"/>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    props: ['form'],
    data() {
        return {
            isLoading: false,
            success: false,
            errors: {},
        }
    },
    methods: {
        async send() {
            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr d\'envoyer à ce numéro de téléphone :  0'+this.form.customer.phone, {
                icon: 'mdi-information',
                agreeText: 'Envoyer',
                color: 'orange',
            })) {
                this.isLoading = true
                this.form.send_gift_done = false
                this.errors = {}
                HTTP.post('/animator-view/send-digital-gift', {
                    phone: "0" + this.form.customer.phone,
                    campaign_id: this.form.campaign.id,
                    gift_id: this.form.gift.id,
                }).then(() => {
                    this.isLoading = false
                    this.form.send_gift_done = true
                    this.$emit('add-to-sale')
                }).catch(err => {
                    this.isLoading = false
                    if (err.response.status === 422){
                        this.errors = err.response.data.errors
                    }
                    this.$errorMessage = "Une erreur s'est produite, veuillez réessayer"
                    console.log(err)
                })
            }
        },
    }
}
</script>

<style scoped>

</style>