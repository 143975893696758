<template>
  <div>

    <v-row v-if="startGame" justify="center">
      <v-col v-for="(item,index) in 3" :key="index" class="text-center" cols="4" lg="3">
        <v-card class="d-flex align-center justify-center rounded-lg"
                color="primary"
                dark
                flat
                max-height="120"
                min-height="120">
          <v-card-text>
            <transition v-if="gifts[countDown]" mode="in-out" name="slide-fade">
              <div :key="gifts[countDown]['name']">
                <v-avatar size="50">
                  <v-img :src="$baseUrl + gifts[countDown]['image']"></v-img>
                </v-avatar>
                <p class="pt-2 mb-0 fs-12 white--text">
                  {{ gifts[countDown]['name'] }}
                </p>
              </div>
            </transition>
            <div v-else>
              <v-avatar color="white" size="50">
                <v-icon color="orange">mdi-information</v-icon>
              </v-avatar>
              <p class="pt-2 mb-0 fs-12 white--text">Cadeau!</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div v-if="!startGame">
      <v-row justify="center">
        <v-col v-for="(item,index) in 3" :key="index" class="text-center" cols="4" lg="3">
          <v-card class="d-flex align-center justify-center rounded-lg"
                  color="primary"
                  dark
                  flat
                  max-height="120"
                  min-height="120">
            <v-card-text>
              <v-avatar color="white" size="50">
                <v-img v-if="form.gift['image']"
                       :src="$baseUrl + form.gift['image']"></v-img>
                <v-icon v-else color="orange">mdi-information</v-icon>
              </v-avatar>
              <p class="pt-2 mb-0 fs-12 white--text font-weight-medium">
                {{ form.gift['name'] ? form.gift['name'] : 'Cadeau!' }}
                  <br>
                {{form.gift.is_digital ?  form.gift.pivot.digital_amount +' DZD' : '' }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="text-center pt-5">
      <v-btn :disabled="!!(endGame || startGame || Object.keys(form.gift).length)"
             color="primary"
             depressed
             @click="[startGame = true,countDown=50,play()]">
        <v-icon left>mdi-play-circle</v-icon>
        Jouer
      </v-btn>
    </div>

  </div>
</template>

<script>


export default {
    name: 'JackpotGame',
    props: ['campaign', 'form'],
    data() {
        return {
            gifts: [],
            countDown: 50,
            startGame: false,
            endGame: false,
        }
    },
    methods: {
        play() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.play()
                }, 100)
            } else {
                this.startGame = false
                this.endGame = true

                const gifts = JSON.parse(JSON.stringify(this.campaign.gifts))
                const res = Math.floor(Math.random() * gifts.length);
                this.form.gift = gifts[res]
            }
        },

    },
    created() {
        let length = 50 / this.campaign.gifts.length
        for (let i = 0; i < length; i++) {
            this.campaign.gifts.map(gift => {
                this.gifts.push(gift)
            })
        }
    }
}
</script>

<style>

</style>