<template>
  <div>

    <ConfirmDialog ref="confirmDialog"/>
    <Lightbox ref="lightbox"/>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card flat tile>
        <v-card-title>
          {{ type === 'start_work' ? 'Début de travail' : 'Fin de travail' }}
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>
          <span class="fs-14 primary--text"> {{ salepoint.name }} </span>
        </v-card-subtitle>
        <v-divider/>
        <v-card-text class="pa-6">

          <p class="text-center font-weight-medium fs-15">
            Prendre une photo
          </p>

          <p class="text-center">
            <span class="font-weight-medium warning--text">Attention,</span> Vous ne pourrez pas revenir en arrière.
          </p>

          <v-layout align-center class="text-center mb-3" justify-center>
            <v-flex shrink>
              <div class="profile-img">
                <v-img :src="image ? image : require('@/assets/no-image.png')"
                       class="rounded-circle"
                       height="150"
                       width="150">
                </v-img>
              </div>

              <v-btn v-if="!image"
                     :loading="isLoading"
                     class="btn-upload"
                     color="primary"
                     dark
                     depressed
                     fab
                     @click="onButtonClick">
                <v-icon>mdi-camera</v-icon>
              </v-btn>

              <v-btn v-if="image"
                     class="btn-upload"
                     color="red"
                     dark
                     depressed
                     fab
                     @click="image = null">
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </v-flex>
          </v-layout>

          <input ref="image"
                 accept="image/*"
                 capture="user"
                 class="d-none"
                 type="file"
                 @change="uploadImage($event)">

          <div v-if="type === 'start_work'">

            <p class="font-weight-medium" v-if="gifts.length">
              Entrez la quantité des cadeaux <span class="red--text">(*)</span>
            </p>

            <v-card v-for="(gift,i) in gifts" :key="i" class="rounded-lg mb-2" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="6">
                    <div class="d-flex align-center">
                      <v-avatar size="50" tile>
                        <v-img :src="$baseUrl + gift.image"
                               class="zoom-pointer rounded"
                               @click="$refs.lightbox.open(gift.image)"
                        ></v-img>
                      </v-avatar>
                      &nbsp;
                      {{ gift.name }}
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="gift.quantity" v-number :error-messages="errors['err_'+i]" dense
                                  filled
                                  hide-details="auto"
                                  min="0"
                                  suffix="Qte"
                                  type="number"
                                  @input="errors['err_'+i] ? errors['err_'+i] = '' : false"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

          </div>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="!image"
                 color="secondary"
                 depressed
                 @click="validate">
            <v-icon left>mdi-check</v-icon>
            Valider
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <NewSaleDialog ref="newSaleDialog"/>

  </div>
</template>

<script>
import moment from "moment/moment";
import NewSaleDialog from "@/views/animator-view/campaigns/program/components/NewSaleDialog.vue";
import imageCompression from 'browser-image-compression';

export default {
    components: {NewSaleDialog},
    data() {
        return {
            is_backup: false,
            dialog: false,
            isLoading: false,
            image: null,
            type: null,

            campaign: {},
            date: null,
            salepoint: {},

            errors: {},
            gifts: []
        }
    },
    methods: {
        open(type, campaign, date, salepoint, is_backup) {
            this.is_backup = is_backup
            this.campaign = campaign
            this.gifts = JSON.parse(JSON.stringify(campaign.gifts))
                .filter(el => el.is_digital === 0)
                .map(el => {
                    return {
                        id: el.id,
                        name: el.name,
                        image: el.image,
                        quantity: '',
                    }
                })
            this.date = date
            this.salepoint = salepoint

            this.type = type
            this.errors = {}
            this.dialog = true
            this.reset()
        },
        reset() {
            this.image = null
        },
        async uploadImage(event) {
            this.isLoading = true
            const imageFile = event.target.files[0]
            const options = {
                maxSizeMB: 0.3,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(imageFile, options)
                if (compressedFile) {
                    const reader = new FileReader()
                    reader.onloadend = () => {
                        this.image = reader.result
                    }
                    reader.readAsDataURL(compressedFile)
                    this.isLoading = false
                }
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        },
        onButtonClick() {
            window.addEventListener('focus', () => {
            }, {once: true})
            this.$refs.image.click()
        },
        validate() {

            /**
             * valiation
             */
            this.errors = {}
            this.gifts.map((item, index) => {
                if (!item.quantity.length) {
                    this.errors['err_' + index] = "Ce champ est obligatoir."
                }
            })

            if (this.type === 'start_work' && Object.keys(this.errors).length === 0) {
                this.handleSave()

                let event = {
                    campaign: this.campaign,
                    salepoint: this.salepoint,
                    date: this.date,
                    is_backup: this.is_backup,
                }
                this.$emit('openSaleDialog', event)
            }

            if (this.type === 'end_work') {
                this.handleSave()
            }
        },
        handleSave() {
            let payload = {
                created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                is_sync: false,
                campaign_id: this.campaign.id,
                salepoint_id: this.salepoint.id,
                date: this.date,
                type: this.type,
                time: moment().format('HH:mm:ss'),
                image: this.image,
                latitude: 0.0,
                longitude: 0.0,
                gifts: this.gifts,
            }
            this.$store.dispatch('pointing', payload)
            this.$successMessage = this.type === 'start_work' ? "Le début de travail a été enregistré" : "Le fin de travail a été enregistré"
            this.dialog = false
            this.$forceUpdate()
        }
    }
}
</script>

<style scoped>
.profile-img {
    border: solid 1px #e9ebec;
    border-radius: 50%;
    padding: 5px;
}

.btn-upload {
    margin-right: -100px;
    margin-top: -65px;
}
</style>